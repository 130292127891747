<template>
  
    <div class="reset">
      <div class="left_side" :style="onload ? 'left: 70px; opacity: .7;' : null">

        <form @submit.prevent="signInClicked" class="form">
          <h2>Reset password</h2>
          <p class="err_message" v-if="err_pass" v-text="state.errMsg"></p>
          
          <label>
            <input :type="state.fieldType" :class="{ 'err_pass': err_pass }" @keypress="err_pass = false" placeholder="Enter New Password" v-model="newPassword">
            <span class="eye" @click="passToggle">
              <span v-if="state.showPass"></span>
            </span>
          </label>
          
          <label>
            <input :type="state.fieldType" :class="{ 'err_pass': err_pass }" @keypress="err_pass = false" placeholder="Enter New Password" v-model="newPassword">
            <span class="eye" @click="passToggle">
              <span v-if="state.showPass"></span>
            </span>
          </label>
          
          <button class="submit" @click="newPasswordEntered(password)">SUBMIT</button>
        </form>

      </div>

      <div class="right_side" :style="onload ? 'right: 70px; opacity: .7;' : null">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
    </div>
  
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword";

let onload = ref(true);
let err_pass = ref(false);
let newPassword = ref('');
let state = reactive({
  showPass: false,
  fieldType: 'password',
  errMsg: 'Wrong Password',
});
// let errMsg = 'Wrong Password';
const passToggle = () => {
  state.showPass = !state.showPass;
  state.fieldType = state.fieldType === 'password' ? 'text' : 'password';
};

async function newPasswordEntered() {
  console.log(newPassword.value,'newPassword')
    try {
        let response = await submitNewPassword({
            formFields: [{
                id: "password",
                value: newPassword.value
            }]
        });
        console.log(newPassword,'newPassword')
        if (response.status === "FIELD_ERROR") {
            response.formFields.forEach(formField => {
                if (formField.id === "password") {
                    // New password did not meet password criteria on the backend.
                    err_pass.value = true
                    state.errMsg = formField.error
                }
            })
        } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
            window.alert("Password reset failed. Please try again")
        } else {
            window.alert("Password reset successful!")
        }
    } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            console.log("dude! " + err.message);
        } else {
           console.log("Oops! Something went wrong.");
        }
    }
}

onMounted(() => {
  setTimeout(() => onload.value = false, 10);
});    
</script>

<style lang="scss" scoped>
.reset {
  background-color: #f8f8f8;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .left_side, .right_side {
    transition: all 0.3s ease;
  }

  .left_side {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #f8f8f8;

    .form {
      overflow: hidden;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 400px;
      text-align: center;

      .err_login, .err_pass {
        border: 1px solid #CD180A;
      }

      .err_message {
        margin: 0;
        font-size: 13px;
        text-align: left;
        line-height: 16px;
        margin-top: -10px;
        margin-bottom: 10px;
        color: #CD180A;
        text-indent: 0px;
      }

      label {
        position: relative;
        margin-top: 5px;
        margin-bottom: 16px;
        display: block;
        left: 0;
      }

      .eye {
        display: inline-block;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        background-image: url(../../public/img/password.png);
        background-position: center;
        background-repeat: no-repeat;

        span {
          display: inline-block;
          height: 27px;
          width: 2px;
          background-color: #787878;
          transform: rotate(60deg);
          position: relative;
          top: 7px;
          right: 0;
        }
      }

      > p {
        color: #787878;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      h2 {
        margin-top: 0px;
        font-size: 32px;
        line-height: 40px;
        height: 40px;
        margin-bottom: 16px;
      }

      input, button {
        max-width: 400px;
        box-sizing: border-box;
        border: none;
        outline: none;
        border-radius: 8px;
        transition: all .3s;
      }

      button:hover {
        background-color: #f2f8f5;
        color: #006C3E;
        border: 1px solid #006C3E;
      }

      input {
        width: 100%;
        border: 1px solid #fff;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.02);
        text-indent: 16px;
        color: #787878;
        font-size: 17px;
        position: relative;
      }

      input::placeholder {
        color: #787878;
        font-size: 17px;
        font-family: 'Inter', sans-serif;
      }

      button {
        width: 100%;
        height: 48px;
        background-color: #006C3E;
        font-size: 17px;
        color: #fff;
      }
    }
  }

  .right_side {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-color: #006C3E;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../public/img/w7login.png);

    .top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 40%;
      transform: rotate(180deg);
    }

    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
    }

    .top, .bottom {
      background-image: url(../../public/img/vectors.png);
      background-position: bottom;
      background-repeat: repeat-x;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px) and (min-height: 961px) and (orientation: portrait) {
  .reset {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: top;
      background-repeat: repeat-x;
      width: 100%;

      .form {
        background-image: url(../../public/img/w7_green.svg);
        background-size: 58px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 50px;
        top: 48%;
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-width: 599px) and (orientation: portrait) {
  .reset {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: center -60px;
      background-repeat: repeat-x;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;

      .form {
        background-image: url(../../public/img/w7_green.svg);
        background-size: 44px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 50px;
        width: inherit;
        box-sizing: border-box;
        position: relative;
        margin-top: 230px;
        text-align: center;
        top: auto;
        left: auto;
        margin-left: auto;

        h2 {
          font-size: 24px;
          line-height: 32px;
          height: 32px;
          margin-bottom: 8px;
        }
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
  .reset {
    .left_side {
      width: 100%;
    }

    .right_side {
      display: none;
    }
  }
}

.slide-err-enter-active,
.slide-err-leave-active {
  transition: all 0.3s ease-out;
}

.slide-err-enter-from,
.slide-err-leave-to {
  margin-top: -30px;
}
</style>
